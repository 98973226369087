import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

export function TaxesFilter({
  impostosData,
  impostosSelecionados,
  setImpostosSelecionados,
}) {
  const { t } = useTranslation();
  const options = impostosData
    ? impostosData.map((imposto) => ({
        label: `${imposto.nome}`,
        value: imposto.areaatuacao_id,
      }))
    : [];

  const selectedOptions = options.filter((option) =>
    impostosSelecionados.includes(option.value)
  );

  const handleChange = (selectedOptions) => {
    setImpostosSelecionados(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  };

  return (
    <div className="mt-4">
      <div className="mb-2">{t("biblioteca.taxesTypes")}</div>
      <Select
        value={selectedOptions}
        isMulti
        name="impostos"
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleChange}
        placeholder={t("biblioteca.selectTaxes")}
        noOptionsMessage={() => {
          t("biblioteca.emptyTaxes");
        }}
      />
    </div>
  );
}
